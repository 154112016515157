import { LngLatBounds, LngLatLike, Map as MapBox, MapOptions, PaddingOptions } from 'mapbox-gl';

export let Map: MapBox;
export const COORD_SP = [-46.6, -23.65] as LngLatLike;
export const NE = [-46.26899625443488, -23.207430336453598] as LngLatLike;
export const SW = [-46.958301757700525, -24.100348741932493] as LngLatLike;

export function Create(options: MapOptions): void {
  Map = new MapBox(options);
}

export function FlyToFeature(feature: GeoJSON.Feature): void {
  let coordinates = [];
  // Tem que "achatar" o array de coordinates para 1 dimensão.
  switch (feature.geometry.type) {
    case 'Point':
      // Point é o único caso que não dá para fazer map.fitBounds() porque não tem bounds.
      const currentZoom = Map.getZoom();
      Map.flyTo({
        duration: 200,
        zoom: Math.max(16, currentZoom),
        center: [feature.geometry.coordinates[0], feature.geometry.coordinates[1]],
        padding: getPaddingAccordingToScreen(),
      });
      return;
    case 'Polygon':
      coordinates = feature.geometry.coordinates[0];
      break;
    case 'MultiPolygon':
      for (let i = 0; i < feature.geometry.coordinates.length; i++) {
        for (let j = 0; j < feature.geometry.coordinates[i].length; j++) {
          for (let k = 0; k < feature.geometry.coordinates[i][j].length; k++) {
            coordinates.push(feature.geometry.coordinates[i][j][k]);
          }
        }
      }
      break;
    case 'LineString':
      coordinates = feature.geometry.coordinates;
      break;
    case 'MultiLineString':
      for (let i = 0; i < feature.geometry.coordinates.length; i++) {
        for (let j = 0; j < feature.geometry.coordinates[i].length; j++) {
          for (let k = 0; k < feature.geometry.coordinates[i][j].length; k++) {
            coordinates.push(feature.geometry.coordinates[i][j]);
          }
        }
      }
      break;
    default:
      throw 'feature.geometry.type desconhecido:' + feature.geometry.type;
  }
  let bounds = coordinates.reduce(
    function (bounds: { extend: (arg0: any) => any }, coord: any) {
      return bounds.extend(coord);
    },
    new LngLatBounds(coordinates[0], coordinates[0]),
  );

  Map.fitBounds(bounds, { padding: getPaddingAccordingToScreen(), maxZoom: 16 });
}

export function PanToFeature(feature: GeoJSON.Feature): void {
  let coordinates = [];
  // Tem que "achatar" o array de coordinates para 1 dimensão.
  switch (feature.geometry.type) {
    case 'Point':
      Map.panTo([feature.geometry.coordinates[0], feature.geometry.coordinates[1]]);
      return;
    case 'Polygon':
      coordinates = feature.geometry.coordinates[0];
      break;
    case 'MultiPolygon':
      for (let i = 0; i < feature.geometry.coordinates.length; i++) {
        for (let j = 0; j < feature.geometry.coordinates[i].length; j++) {
          for (let k = 0; k < feature.geometry.coordinates[i][j].length; k++) {
            coordinates.push(feature.geometry.coordinates[i][j][k]);
          }
        }
      }
      break;
    case 'LineString':
      coordinates = feature.geometry.coordinates;
      break;
    case 'MultiLineString':
      for (let i = 0; i < feature.geometry.coordinates.length; i++) {
        for (let j = 0; j < feature.geometry.coordinates[i].length; j++) {
          for (let k = 0; k < feature.geometry.coordinates[i][j].length; k++) {
            coordinates.push(feature.geometry.coordinates[i][j]);
          }
        }
      }
      break;
    default:
      throw 'feature.geometry.type desconhecido:' + feature.geometry.type;
  }
  let bounds = coordinates.reduce(
    function (bounds: { extend: (arg0: any) => any }, coord: any) {
      return bounds.extend(coord);
    },
    new LngLatBounds(coordinates[0], coordinates[0]),
  );
  Map.panTo(bounds.getCenter());
}

function getPaddingAccordingToScreen(): PaddingOptions {
  switch (screen.orientation.type) {
    case 'landscape-primary':
    case 'landscape-secondary': // screen is upside down
      return { top: 8, bottom: 8, left: 488, right: 8 };
    case 'portrait-primary':
    case 'portrait-secondary': // screen is upside down
      return { top: 8, bottom: 330, left: 8, right: 8 };
    default:
      return { top: 8, bottom: 8, left: 8, right: 8 };
  }
}

function inBounds(coord: LngLatLike): boolean {
  const bounds = Map.getBounds();
  return bounds.contains(coord);
}

export function EstaDentro(feature: GeoJSON.Feature): boolean {
  if (feature.geometry.type === 'Point') {
    const coords = feature.geometry['coordinates'] as LngLatLike;
    return inBounds(coords);
  }
  if (feature.geometry.type === 'LineString') {
    const coords = feature.geometry.coordinates as LngLatLike[];
    for (let i = 0; i < coords.length; i++) {
      if (!inBounds(coords[i])) return false;
    }
    return true;
  }
  if (feature.geometry.type === 'MultiLineString') {
    for (let i = 0; i < feature.geometry.coordinates.length; i++) {
      const coords = feature.geometry.coordinates[i] as LngLatLike[];
      for (let j = 0; j < coords.length; j++) {
        if (!inBounds(coords[j])) return false;
      }
    }
    return true;
  }
  // if (feature.geometry.type === 'Polygon') {
  //   for (let i = 0; i < coords.length; i++) {
  //     for (let j = 0; j < coords[i].length; j++) {
  //       if (!InBounds(coords[i][j])) return false;
  //     }
  //   }
  //   return true;
  // }
  if (feature.geometry.type === 'MultiPolygon') {
    for (let i = 0; i < feature.geometry.coordinates.length; i++) {
      const coords = feature.geometry.coordinates[i] as LngLatLike[][]; // LngLatLike[][];
      for (let j = 0; j < coords.length; j++) {
        for (let k = 0; k < coords[j].length; k++) {
          if (!inBounds(coords[j][k])) return false;
        }
      }
    }
    return true;
  }
  return false;
}

export function IsFeaturePartialyVisible(feature: GeoJSON.Feature): boolean {
  let coordinates = [];
  // Tem que "achatar" o array de coordinates para 1 dimensão.
  switch (feature.geometry.type) {
    case 'Point':
      return inBounds([feature.geometry.coordinates[0], feature.geometry.coordinates[1]]);
    case 'Polygon':
      coordinates = feature.geometry.coordinates[0];
      break;
    case 'MultiPolygon':
      for (let i = 0; i < feature.geometry.coordinates.length; i++) {
        for (let j = 0; j < feature.geometry.coordinates[i].length; j++) {
          for (let k = 0; k < feature.geometry.coordinates[i][j].length; k++) {
            coordinates.push(feature.geometry.coordinates[i][j][k]);
          }
        }
      }
      break;
    case 'LineString':
      coordinates = feature.geometry.coordinates;
      break;
    case 'MultiLineString':
      for (let i = 0; i < feature.geometry.coordinates.length; i++) {
        for (let j = 0; j < feature.geometry.coordinates[i].length; j++) {
          for (let k = 0; k < feature.geometry.coordinates[i][j].length; k++) {
            coordinates.push(feature.geometry.coordinates[i][j]);
          }
        }
      }
      break;
    default:
      throw 'feature.geometry.type desconhecido:' + feature.geometry.type;
  }
  for (let i = 0; i < coordinates.length; i++) {
    if (inBounds(coordinates[i])) return true;
  }
  return false;
}
